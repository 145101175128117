import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const GirlFridayEP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

            <p className={style.albumDetails__credits}>
              <strong>Executive Producer:</strong> Specyal T <br/>
              <strong>Label:</strong> Independent<br/>
              <strong>Recorded At:</strong> Number Nine Studios <br/>
              <strong>Production Provided By:</strong> O.M.G. <br/>
              <strong>Mixed & Mastered By:</strong> Scotty Komer
            </p>
            
            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>Stay With Me</li>
              <li>Just A Thot</li>
              <li>Madame Jane</li>
              <li>Later Tonite</li>
              <li>When You Knew</li>
            </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

              <p><strong>Stay With Me</strong><br/>
              A couple meet and instantly become infatuated with one another. She is concerned it may all be too good to be true and insists on his reassurance.</p>

              <hr />

              <p><strong>Just A Thot</strong><br/>
              Two friends, now exes, meet up for drinks but are interrupted by his new girlfriend who believes they’re having an affair behind her back. While she’s giving the explanation, she later finds out this new girlfriend of his is far from what he believes to be true and she now has no choice but to confess.</p>

              <hr />

              <p><strong>Madame Jane</strong><br/>
              A dominatrix / seductress preys on a curious woman during bible study at their church. This woman unknowingly ends up being her right hand in a large prostitution type ring that spreads across many cities. The madame’s tracks are covered quite well, so to not go to jail herself, the woman tells detectives all the details so she can be brought to justice finally!</p>

              <hr />

              <p><strong>Later Tonite</strong><br/>
              After happy hour following a long day of work, T and a group of her girlfriends are now looking for something more to entertain them. They get a lead about a club they haven’t been to and travel to a next city to check it out. While there T meets a man and there’s an instant attraction. He than asks her to continue the nite with him.</p>

              <hr />

              <p><strong>When You Knew</strong><br/>
              A couple now on the verge of splitting up are caught in a bitter argument. They have come to this realization after it was revealed that she had been with his brother way before the two of them even started dating. Unable to get over that fact, he mocks them while they take verbal shots at one another.</p>

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default GirlFridayEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/cake.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`